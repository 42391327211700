<template>
	<div class="upload-opus-view">
		<div class="upload-opus-tab">
			<MusicType :width="952" :type="type" :tabsList="tabsList" :dropdownList="dropdownList"></MusicType>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import MusicType from "@/components/content/MusicType"
	import {
		myInfo
	} from "@/api/Mine";
	import {
		getUserInfo
	} from "@/utils/common/user";

	export default {
		props: {},
		data() {
			return {
				// tabs数据
				// tabsList: [],
				tabsList: [
					// {
					// 	name: '成品音乐',
					// 	url: '/uploadopus/finishmusic'
					// },
					// {
					// 	name: 'DEMO(海外)',
					// 	url: '/uploadopus/abroaddemo'
					// },
					// {
					// 	name: 'DEMO(内地)',
					// 	url: '/uploadopus/outbackdemo'
					// },
					{
					  name: this.$t('recommend.WorkCollaboration'),
					  url: '/workupload/collaborationarea'
					}
				],
				tabsList1: [{
					name: this.$t('Musiccertification'),
					url: '/uploadopus/musiciancertification'
				}],
				// 全部下来菜单
				dropdownList: [
					// {
					//   name: '全部'
					// },
					// {
					//   name: '分类1'
					// },
					// {
					//   name: '分类2'
					// }
				],
				type: false
			}
		},
		components: {
			MusicType
		},
		computed: {},
		created() {},
		mounted() {
			// myInfo().then((res) => {

			// 	// this.userName = res.data.name;
			// 	// this.userImage = res.data.image;
			// 	// this.userIntroduce = res.data.introduce;
			// 	// this.userDay = res.data.day;
			// 	// this.userFollow = res.data.follow_count;
			// 	// this.userFavorite = res.data.favorite_count;
			// 	// this.userShopping = res.data.shopping_cart_count;
			// 	// this.userPurchase = res.data.withdrawal;
			// 	this.$store.state.loginname = res.data.name;
			// 	console.log(this.$store.state.loginname, 'myInfo')
			// 	let userInfo = JSON.parse(this.getUserInfo())
			// 	if (userInfo.identity == 3) {
			// 		let arr = [{
			// 				name: '成品音乐',
			// 				url: '/uploadopus/finishmusic'
			// 			},
			// 			{
			// 				name: 'DEMO(海外)',
			// 				url: '/uploadopus/abroaddemo'
			// 			},
			// 			{
			// 				name: 'DEMO(内地)',
			// 				url: '/uploadopus/outbackdemo'
			// 			},
			// 			{
			// 			  name: '作品协作区',
			// 			  url: '/uploadopus/collaborationarea'
			// 			}
			// 		]
			// 		this.tabsList = arr
			// 	} else {
			// 		let arr = [{
			// 			name: '音乐人认证',
			// 			url: '/uploadopus/musiciancertification'
			// 		}]
			// 		this.tabsList = arr
			// 	}
			// 	// this.$store.state.surplus_sum = res.data.surplus;
			// 	// this.$store.state.is_prove = res.data.is_prove;
			// });
		},
		watch: {},
		methods: {
			getUserInfo,

		}
	}
</script>

<style scoped lang='less'>
</style>
